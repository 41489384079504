export default function initHotkeys() {
    // hotkeys bindings not working properly for this kind of action
    document.addEventListener('keydown', (event) => {
        if (event.ctrlKey && event.key === 'k') {
            event.preventDefault();
            document.querySelector('#keywords')?.focus();
            return false;
        }
    });

    // hotkeys bindings not working properly for this kind of action
    document.addEventListener('keydown', (event) => {
        if (event.ctrlKey && event.shiftKey && event.key === 'K') {
            event.preventDefault();
            document.querySelector('#filter-btn')?.click();
            return false;
        }
    });
}
