export function fingerprint() {
    console.log(
        '%c                                                          \n' +
        '                            %c        %c                      \n' +
        '                            %c  %c    %c      %c                  \n' +
        '              %c    %c          %c  %c         %c    %c               \n' +
        '            %c   %c  %c  %c    %c       %c            %c    %c            \n' +
        '             %c   %c  %c  %c  %c   %c                   %c    %c          \n' +
        '               %c   %c %c      %c                     %c   %c         \n' +
        '       %c  %c     %c   %c   %c     %c                       %c  %c        \n' +
        '      %c      %c %c   %c     %c    %c                     %c    %c        \n' +
        '      %c  %c   %c   %c                              %c   %c           \n' +
        '     %c  %c                  %c       %c            %c   %c           \n' +
        '    %c   %c                %c    %c    %c    %c          %c  %c           \n' +
        '    %c        %c          %c  %c         %c   %c          %c        %c    \n' +
        '          %c  %c         %c  %c           %c   %c               %c  %c    \n' +
        '         %c        %c    %c  %c            %c  %c               %c  %c    \n' +
        '            %c     %c    %c  %c           %c   %c   %c      %c      %c  %c    \n' +
        '    %c          %c        %c  %c         %c   %c    %c  %c %c           %c    \n' +
        '    %c   %c                %c    %c    %c    %c    %c  %c                 \n' +
        '     %c  %c                  %c       %c      %c  %c                  \n' +
        '      %c  %c                            %c   %c                   \n' +
        '      %c   %c                            %c   %c                  \n' +
        '       %c   %c                            %c   %c                 \n' +
        '         %c  %c    %c     %c                  %c   %c                 \n' +
        '          %c      %c   %c    %c              %c   %c                  \n' +
        '            %c   %c      %c  %c               %c   %c                 \n' +
        '                    %c   %c                %c   %c                \n' +
        '                    %c   %c           %c      %c                  \n' +
        '                      %c              %c                      \n' +
        '                                                          \n' +
        '                                                          \n' +
        '%c                                                          \n' +
        '   ████████  █████  ███████ ████████ ███████ ███████      \n' +
        '      ██    ██   ██ ██         ██    ██      ██           \n' +
        '      ██    ███████ ███████    ██    █████   █████        \n' +
        '      ██    ██   ██      ██    ██    ██      ██           \n' +
        '      ██    ██   ██ ███████    ██    ███████ ███████      \n' +
        '                                                          \n',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240', 'background: #fff', 'background: #1f1240', 'background: #fff',
        'background: #1f1240',
        'color: white; background-color: #1f1240; text-shadow: 3px 3px 0 rgb(31,18,64) , 6px 6px 0 rgb(67,57,94) , 9px 9px 0 rgb(117,110,135) , 12px 12px 0 rgb(141,136,155) , 15px 15px 0 rgb(167,164,176) , 18px 18px 0 rgb(196,194,199) , 21px 21px 0 rgb(210,212,220)');
    console.log('https://winespace.fr')
}
