import '@hotwired/turbo-rails';
import './controllers';
import './config';
import './channels';

import jquery from 'jquery';

window.$ = jquery;
window.jQuery = jquery;

require('./utils.js');

// plugins
import {initCarousel} from './plugins/carousel';
import initClipboard from './plugins/clipboard';
import {fingerprint} from './plugins/fingerprint';
import initHotkeys from './plugins/hotkeys';
import {initSelect2} from './plugins/select2';
import {initSweetAlert, setConfirmDialog} from './plugins/sweet_alert';
import initToastr from './plugins/toastr';

initCarousel();
initClipboard();
initSelect2();
initSweetAlert();
setConfirmDialog();
initToastr();

(() => {
    fingerprint();
    initHotkeys()
})();
