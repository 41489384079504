import {formatItem} from '@js/helpers/select2/templates';

// helper to creating scrolling select2 elements
export class Select2Helper {
    constructor(url, {formatType = 'default', minimumInputLength = 3, delay = 250} = {}) {
        this.url = url;
        this.formatType = formatType;
        this.minimumInputLength = minimumInputLength;
        this.delay = delay;
    }

    initializeSelect2(el) {
        $(el).select2({
            ajax: {
                url: this.url,
                data: (params) => ({
                    args: {
                        q: params.term,
                        page: params.page || 1,
                    },
                }),
                processResults: (data) => data.items,
                dataType: 'json',
                delay: this.delay,
            },
            minimumInputLength: this.minimumInputLength,
            placeholder: '',
            language: window.lang(),
            templateResult: formatItem(this.formatType),
            templateSelection: formatItem(this.formatType),
        });
    }
}
