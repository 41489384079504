import {Controller} from '@hotwired/stimulus';
import {FormCache} from '../helpers/cache_helper';
import {AxiosRequest} from '../helpers/axios_helper';

export default class extends Controller {
    connect() {
        // clear cache if page is reloaded
        window.addEventListener('load', () => {
            if (window.pageReloaded()) {
                new FormCache('searchCache').clear();
            }
        })
    }

    setCache(event) {
        new FormCache('searchCache').cacheForm(event.target);
    }

    loadCacheAndSendRequest(event) {
        const formCache = new FormCache('searchCache');

        // verify if cache is empty
        if (formCache.storage.length === 0) {
            return;
        }

        // clear cache when the current cache is for the current page (page that we are quitting)
        // i.e. I have a cache for /items/1, I'm on /items/1, I'm going back to /items
        if (formCache.get('url') === window.location.pathname) {
            formCache.clear();
            return;
        }

        const action = formCache.get('action');
        const path = event.currentTarget.href;
        const cachePath = event.currentTarget.dataset.cachePath;

        // verify if cache is needed
        if (action !== cachePath) {
            return;
        }

        event.preventDefault();

        // create params to send to server
        const data = new FormData();
        formCache.storage.forEach(field => {
            data.append(field.name, field.value);
        });
        data.append('cache', 'true');

        new AxiosRequest(cachePath, 'post', data)
            .sendTurboStream()
            .then(() => {
                // submit form if it was a form request or do a simple visit
                // need to add flash.keep on the controller to keep the flash message on the redirection
                if (event.params.formRequest) {
                    event.target.form.requestSubmit();
                } else {
                    Turbo.visit(path, {action: 'replace', frame: '_top'});
                }
            });
    }

    clearCache(_event) {
        new FormCache('searchCache').clear();
    }
}
