import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    updateHeader(event) {
        const header = event.target.closest('.accordion-item').querySelector('.content-status');
        const formData = new FormData(event.target);
        const filled = Array.from(formData.entries()).filter(([name, value]) => name.includes('destroy') && value !== '1').length > 0;
        header.classList.toggle('d-none', !filled);
    }
}
