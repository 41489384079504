import {Controller} from '@hotwired/stimulus';
import {formatItem} from '@js/helpers/select2/templates';
import {matcher} from '@js/helpers/select2/matchers';

export default class extends Controller {
    static targets = ['select'];

    static values = {
        placeholder: {type: String, default: ''},
        formatWith: {type: String, default: 'default'},
        matcher: {type: String, default: 'default'},
    };

    connect() {
        this.selectTargets.forEach(select => {
            $(select).select2({
                placeholder: this.placeholderValue,
                language: window.lang(),
                matcher: matcher(this.matcherValue),
                templateResult: formatItem(this.formatWithValue),
                templateSelection: formatItem(this.formatWithValue),
            })
            // Prevent Turbo from caching the select2 causing duplicates
            select.nextElementSibling.setAttribute('data-turbo-temporary', 'true');
        });

        this.triggerChangeEvent();
    }

    triggerChangeEvent() {
        $(this.selectTargets).on('select2:select select2:unselect', function (e) {
            if (!e.params) return;

            let data = e.params.data;
            const event = new Event('change', {bubbles: true});

            // Put attributes for event
            event.selectType = e.type;
            event.text = data.text;
            event.id = data.id;

            this.dispatchEvent(event);
        });
    }
}
