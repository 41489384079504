import {Controller} from '@hotwired/stimulus';
import {AxiosRequest} from '@js/helpers/axios_helper';
import {ConfirmSwal, SyntaxAlertSwal} from '@js/helpers/swal_helper';
import {Select2Helper} from '@js/helpers/select2_helper';

export default class extends Controller {
    static targets = [
        'appellationSelect',
        'cuveeName',
        'domainName',
        'colorTypeId',
        'millesimeYear',
        'sampleTypeId',
    ];

    appellationSelectTargetConnected(element) {
        const appellationSelectHelper = new Select2Helper(`/${window.lang()}/items/appellation`, { formatType: 'appellation' });
        appellationSelectHelper.initializeSelect2(element);
    }

    fillInputs(event) {
        const data = event.detail.selected.dataset;
        this.domainNameTarget.value = data.cuveeDomain;
        this.cuveeNameTarget.value = data.cuveeCuvee;
        this.colorTypeIdTarget.value = data.cuveeColorTypeId;

        // Trigger select2 changes
        // Color type select
        $(this.colorTypeIdTarget).trigger('change');
        // Appellation select
        let option = new Option(data.cuveeAppellationFullname, data.cuveeAppellationId, true, true);
        option.setAttribute('data-iso-code', data.cuveeAppellationIsoCode)
        $(this.appellationSelectTarget).append(option).trigger('change');

        // Go to the millesime field
        this.millesimeYearTarget.scrollIntoView();

        // Clear the search field
        document.getElementById('search').value = '';
    }

    async validateStepOne(event) {
        event.preventDefault();

        if (!event.target.form.checkValidity()) {
            event.target.form.reportValidity();
            return;
        }

        const currentTargetDataset = event.currentTarget.dataset;

        // Display a confirmation modal if needed
        if (currentTargetDataset.turboConfirm) {
            const confirmed = await new ConfirmSwal(JSON.parse(currentTargetDataset.turboConfirm)).fire();
            if (!confirmed) {
                return;
            }
        }

        const headers = new Headers({
            'accept': 'application/json',
        });
        const translations = JSON.parse(currentTargetDataset.translations);

        new AxiosRequest(
            '/comments/valid_identification',
            'get',
            {cuvee: this.cuveeNameTarget.value, domain: this.domainNameTarget.value},
            headers)
            .sendHtml()
            .then((response) => {
                    const data = response.data;
                    if (data.values.domain !== null || data.values.cuvee !== null) {
                        this.syntaxAlert(data.values, translations);
                    } else {
                        event.target.form.requestSubmit();
                    }
                },
            );
    }

    syntaxAlert(newValues, translations) {
        new SyntaxAlertSwal(translations).fire().then((result) => {
            if (result.isConfirmed) {
                if (newValues.cuvee !== null) {
                    this.cuveeNameTarget.value = newValues.cuvee;
                }
                if (newValues.domain !== null) {
                    this.domainNameTarget.value = newValues.domain;
                }
            }
        });
    }
}
