import {Controller} from '@hotwired/stimulus';
import Tab from 'bootstrap/js/dist/tab';

export default class extends Controller {
    static values = {
        disableCache: {type: Boolean, default: false},
    };

    connect() {
        Turbo.cache.exemptPageFromCache();
        if (this.disableCacheValue) { return; }

        // on load of the page: switch to the currently selected tab or the stored one
        this.switchTab();

        // store the currently selected tab in the hash value
        document.querySelectorAll('ul.nav-pills > li > a')
            .forEach((tab) => {
                tab.addEventListener('shown.bs.tab', (event) => {
                    this.storeCurrentTab(event);
                });
            });
    }

    storeCurrentTab(event) {
        const id = event.target.getAttribute('href').substring(1);
        localStorage.setItem(
            'selectedTab',
            JSON.stringify({page: window.location.pathname, hash: id}),
        );
        window.location.hash = id;
    }

    switchTab() {
        const selectedTab = JSON.parse(localStorage.getItem('selectedTab'));
        let hash = null;

        if (window.location.hash !== '') {
            hash = window.location.hash;
        } else if (selectedTab !== null && selectedTab.page === window.location.pathname) {
            hash = `#${selectedTab.hash}`;
        }

        if (hash !== null) {
            const tabEl = document.querySelector('#pills-tab a[href="' + hash + '"]');
            if (tabEl && tabEl.length !== null) {
                const tab = new Tab(tabEl);
                tab.show();
            }
        }
    }
}
