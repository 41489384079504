import ApplicationController from '../application_controller';
import {AxiosRequest} from '@js/helpers/axios_helper';
import {SyntaxAlertSwal} from '@js/helpers/swal_helper';
import {DirectUpload} from '@rails/activestorage';
import * as FilePond from 'filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import {KeyValueCache} from '@js/helpers/cache_helper';
import {setFilePondLocale} from '@js/plugins/filepond';
import {csrfToken} from '@js/utils';

export default class extends ApplicationController {
    static targets = ['submitBtn', 'form', 'fileField'];

    static outlets = ['forms--nested-fields'];

    static values = {
        directUploadUrl: String,
    };

    initialize() {
        this.clearSession();
    }

    disconnect() {
        this.clearSession();
    }

    // When the nested form controller is connected, we can initialize the nested fields (for images)
    // and listen to events to save the nested fields to the session
    formsNestedFieldsOutletConnected(outlet, element) {
        const commentSession = new KeyValueCache('commentSession');

        // Initialize nested fields
        if (commentSession.get('photos_attributes') === undefined) {
            commentSession.set('photos_attributes', []);
        }

        // Fill session with existing nested fields
        element.querySelectorAll('.nested-fields').forEach((nestedField) => {
            const nestedId = this.formsNestedFieldsOutlet.nestedId(nestedField);
            if (commentSession.get('photos_attributes', nestedId) === undefined) {
                commentSession.set(
                    'photos_attributes',
                    {nestedId: nestedId, ...this.formsNestedFieldsOutlet.nestedFieldValues(nestedField)},
                    nestedId,
                );
            }
        });

        // Save session
        commentSession.store();

        // Listen to new nested fields events
        element.addEventListener('nested-field:added', (event) => {
            commentSession.set('photos_attributes', {nestedId: event.detail.nestedId}, '');
            commentSession.store();
        });

        // Listen to removed nested fields events
        element.addEventListener('nested-field:removed', (event) => {
            commentSession.remove('photos_attributes', event.detail.nestedId);
            commentSession.store();
        });

        // Listen to hidden nested fields events
        element.addEventListener('nested-field:hidden', (event) => {
            commentSession.set(
                'photos_attributes',
                {...commentSession.get('photos_attributes', event.detail.nestedId), _destroy: 'true'},
                event.detail.nestedId,
            );
            commentSession.store();
        });
    }

    formTargetConnected() {
        this.saveFormToSession();
    }

    // Initialize filepond elements for each file field
    async fileFieldTargetConnected(element) {
        await this.initFilePond(element);
    }

    addPhotoField(event) {
        // Create a hidden input to display the file picker
        const hiddenFileField = document.createElement('input');
        hiddenFileField.type = 'file';
        hiddenFileField.accept = event.params.authorizedFiles;
        hiddenFileField.style.display = 'none';
        document.body.appendChild(hiddenFileField);
        hiddenFileField.click();

        // On file selection, add filepond with the selected file, then remove the hidden input
        // else only remove the hidden input
        hiddenFileField.addEventListener('change', () => {
            this.formsNestedFieldsOutlet.addNestedField(event);
            document.addEventListener('FilePond:init', async () => {
                const nestedFields = document.querySelectorAll('.nested-fields');
                const nestedField = nestedFields[nestedFields.length - 1];
                const pond = nestedField.querySelector('.filepond--root');
                await FilePond.find(pond).addFile(hiddenFileField.files[0])
                    .then(() => {
                        if (event.params.successMessage !== undefined) {
                            pond.addEventListener('FilePond:processfile', () => toastr.success(event.params.successMessage), {once: true});
                        }
                    })
                    .catch((error) => {
                        toastr.error(`${error.error.main}. ${error.error.sub}`);
                        nestedField.remove();
                    });

                hiddenFileField.remove();
            }, {once: true});
        }, {once: true});
        hiddenFileField.addEventListener('cancel', () => {
            hiddenFileField.remove();
        }, {once: true});
    }

    // Save an input to session storage
    saveToSession(event) {
        const commentSession = new KeyValueCache('commentSession');
        this.setInputToSession(event.currentTarget, commentSession);
        commentSession.store();
    }

    // Save a nested field to session storage
    saveNestedFieldToSession(event) {
        const commentSession = new KeyValueCache('commentSession');
        const nestedId = this.formsNestedFieldsOutlet.nestedId(event.target.closest('.nested-fields'));
        const fieldName = this.formsNestedFieldsOutlet.nestedFieldName(event.target);
        commentSession.set(
            'photos_attributes',
            {...commentSession.get('photos_attributes', nestedId), [fieldName]: event.target.value.toString()},
            nestedId,
        );
        commentSession.store();
    }

    // Save the form to session storage
    saveFormToSession() {
        const commentSession = new KeyValueCache('commentSession');
        Array.from(this.formTarget.elements).forEach((element) => {
            this.setInputToSession(element, commentSession);
        });
        commentSession.store();
    }

    setInputToSession(input, session) {
        let value = input.type === 'checkbox' ? input.checked : input.value;
        session.set(input.name, value);
    }

    clearSession() {
        new KeyValueCache('commentSession').clear();
    }

    // Update submit button state given the form validity and an eventual state
    updateSubmitBtn(state = true) {
        if (this.hasSubmitBtnTarget) {
            if (state) {
                this.submitBtnTarget.removeAttribute('disabled');
            } else {
                this.submitBtnTarget.setAttribute('disabled', 'disabled');
            }
        }
    }

    goToForm({params: {component}}) {
        this.stimulate('CommentForm#update_comment_form', new KeyValueCache('commentSession').storage, component);
    }

    validateStepOne(event) {
        event.preventDefault();

        if (!event.target.form.checkValidity()) {
            event.target.form.reportValidity();
            return;
        }

        const cuvee = this.element.querySelector('#cuvee_name');
        const domain = this.element.querySelector('#domain_name');
        const headers = new Headers({
            'accept': 'application/json',
        });
        const translations = JSON.parse(event.currentTarget.dataset.translations);

        new AxiosRequest(
            '/comments/valid_identification',
            'get',
            {cuvee: cuvee.value, domain: domain.value},
            headers)
            .sendHtml()
            .then((response) => {
                    const data = response.data;
                    if (data.values.domain !== null || data.values.cuvee !== null) {
                        this.syntaxAlert(data.values, translations);
                    } else {
                        this.saveFormToSession();
                        // Remove previous simple identification because we validate a full identification
                        const commentSession = new KeyValueCache('commentSession');
                        commentSession.remove('simple_identification');
                        commentSession.store();
                        this.stimulate('CommentForm#update_comment_form', commentSession.storage, 'Forms::Comments::CompleteComponent');
                    }
                },
            );
    }

    async initFilePond(element) {
        // Register FilePond plugins
        FilePond.registerPlugin(
            FilePondPluginImagePreview,
            FilePondPluginImageTransform,
            FilePondPluginFilePoster,
            FilePondPluginFileValidateType,
        );

        setFilePondLocale();

        // Create FilePond instance
        const pond = FilePond.create(element, {
            name: element.name,
            credits: false,
            imageTransformOutputQuality: 60,
            imageTransformOutputMimeType: 'image/jpeg',
            labelIdle: `<div class="fa fa-circle-plus"></div>`,
            allowFilePoster: true,
            styleItemPanelAspectRatio: 1,
            allowRemove: false,
            allowRevert: false,
            allowProcess: false,
        });

        // Set FilePond options
        pond.setOptions({
            server: {
                headers: {
                    'X-CSRF-Token': csrfToken(),
                },
                process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                    const commentSession = new KeyValueCache('commentSession');
                    const uploader = new DirectUpload(file, this.directUploadUrlValue, {
                        directUploadWillStoreFileWithXHR: (request) => {
                            request.upload.addEventListener(
                                'progress',
                                event => progress(event.lengthComputable, event.loaded, event.total),
                            );
                        },
                    });
                    uploader.create((errorResponse, blob) => {
                        if (errorResponse) {
                            error(`Something went wrong: ${errorResponse}`);
                        } else {
                            load(blob.signed_id);
                            const nestedId = this.formsNestedFieldsOutlet.nestedId(pond.element.closest('.nested-fields'));
                            commentSession.set('photos_attributes', {
                                ...commentSession.get('photos_attributes', nestedId),
                                file: blob.signed_id,
                            }, nestedId);
                            commentSession.store();
                        }
                    });

                    return {
                        abort: () => abort(),
                    };
                },
                revert: {
                    url: '/filepond/remove',
                },
                load: {
                    url: '/filepond/load?id=',
                },
                restore: {
                    url: '/filepond/load?id=',
                },
            },
            onaddfilestart: () => {
                this.updateSubmitBtn(false);
            },
            onaddfile: (error, file) => {
                this.updateSubmitBtn(file.origin !== 1);
            },
            onprocessfilestart: () => {
                this.updateSubmitBtn(false);
            },
            onprocessfile: () => {
                this.updateSubmitBtn();
            },
            onwarning: (error) => {
                toastr.error(error.body);
            },
            onerror: (error) => {
                console.error(error);
            },
        });

        if (pond.element.nextElementSibling !== null) {
            pond.files = [
                {
                    source: pond.element.nextElementSibling.value,
                    options: {
                        type: 'local',
                    },
                },
            ];
        }
    }

    syntaxAlert(newValues, translations) {
        new SyntaxAlertSwal(translations).fire().then((result) => {
            if (result.isConfirmed) {
                if (newValues.cuvee !== null) {
                    this.element.querySelector('#cuvee_name').value = newValues.cuvee;
                }
                if (newValues.domain !== null) {
                    this.element.querySelector('#domain_name').value = newValues.domain;
                }
            }
        });
    }
}
