import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['container', 'button'];

    connect() {
        this.updateButtonVisibility();
    }

    toggle() {
        this.containerTarget.classList.toggle('expanded');
        this.updateButtonIcon();
    }

    updateButtonIcon() {
        this.buttonTarget.firstElementChild.classList.toggle('fa-chevron-down');
        this.buttonTarget.firstElementChild.classList.toggle('fa-chevron-up');
    }

    updateButtonVisibility() {
        const isOverflowing = this.containerTarget.scrollHeight > this.containerTarget.clientHeight;
        this.buttonTarget.style.display = isOverflowing ? 'block' : 'none';
    }
}
