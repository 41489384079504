import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['dropdown', 'icon', 'text'];

    static values = {
        display: {type: String, default: 'block'},
    };

    toggle() {
        if (this.hasIconTarget) {
            this.iconTarget.classList.toggle('fa-chevron-down');
            this.iconTarget.classList.toggle('fa-chevron-up');
        }

        if (this.hasTextTarget) {
            let btnText = this.textTarget.textContent.trim();
            let textPlus = this.textTarget.dataset.displayTextPlus.trim();

            btnText = btnText === textPlus ? this.textTarget.dataset.displayTextMoins : this.textTarget.dataset.displayTextPlus;

            this.textTarget.textContent = btnText;
        }

        this.dropdownTarget.classList.toggle('d-none');
        this.dropdownTarget.classList.toggle(`d-${this.displayValue}`);
    }
}
