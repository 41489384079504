// keep track of last visited page
// we use pagehide instead of unload:
// https://web.dev/articles/bfcache?utm_source=lighthouse&utm_medium=devtools&hl=fr#never-use-the-unload-event
const setLastVisited = () => sessionStorage.setItem('lastVisited', window.location.pathname);
addMultipleEventListeners(window, ['pagehide', 'turbo:before-visit'], setLastVisited);

// get current language
function lang() {
    return document.documentElement.lang;
}

// get last visited page
function lastVisited() {
    return sessionStorage.getItem('lastVisited');
}

function pageReloaded() {
    return window.location.pathname === lastVisited();
}

// expose functions to global scope
window.lang = lang;
window.lastVisited = lastVisited;
window.pageReloaded = pageReloaded;

export function csrfToken() {
    return document.head.querySelector("[name='csrf-token']")?.content;
}

// add multiple event listeners to an element with a single handler
export function addMultipleEventListeners(element, events, handler) {
    events.forEach(event => element.addEventListener(event, handler));
}

export function kebabToSnakeCase(str) {
    return str.replace(/-/g, '_');
}

export function kebabToCamelCase(str) {
    return str.replace(/-./g, x=>x[1].toUpperCase());
}

export function snakeToCamelCase(str) {
    return str.replace(/_./g, x=>x[1].toUpperCase());
}
