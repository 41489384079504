import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    passwordToggle(event) {
        const element = event.target;
        const input = element.previousElementSibling; // input needs to be the previous sibling element
        element.classList.toggle('fa-eye-slash');
        element.classList.toggle('fa-eye');
        input.type = input.type === 'password' ? 'text' : 'password';
    }
}
