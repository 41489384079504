import {Controller} from '@hotwired/stimulus';
import flatpickr from 'flatpickr';
import {setFlatpickrLocale} from '@js/plugins/flatpickr';

export default class extends Controller {
    static targets = ['container', 'input', 'from', 'to'];

    static values = {
        mode: {type: String, default: 'single'},
        defaultDate: Array,
    };

    connect() {
        const _this = this;
        const fpElement = this.getFlatpickrElement();
        const initFlatpickr = () => {
            flatpickr(fpElement, {
                altInput: true,
                altInputClass: 'form-control form-control-sm input',
                defaultDate: this.defaultDateValue,
                mode: this.modeValue,
                wrap: true,
                onOpen: function (selectedDates, _dateStr, instance) {
                    instance._input.readOnly = true;
                },
                onChange: function (selectedDates, _dateStr, _instance) {
                    if (selectedDates.length !== 2) {
                        _this.clearSelection();
                        return;
                    }

                    const [from, to] = selectedDates;
                    _this.fromTarget.value = from ?? '';
                    _this.toTarget.value = to ?? '';
                },
                onClose: (selectedDates, _dateStr, instance) => {
                    instance._input.readOnly = false; // allow manual input to enable HTML5 validation

                    // Clear input values if we have don't have 2 dates
                    if (selectedDates.length !== 2) {
                        _this.clearSelection();
                    }
                },
            });
        };


        // Set events
        // Prevent Turbo from caching the flatpickr causing duplicates
        document.addEventListener('turbo:before-cache', () => {
            fpElement._flatpickr?.destroy();
        });
        document.addEventListener('stimulus-reflex:after', initFlatpickr);

        setFlatpickrLocale();
        initFlatpickr();
    }

    clearSelection() {
        this.fromTarget.value = '';
        this.toTarget.value = '';
    }

    getFlatpickrElement() {
        if (this.hasContainerTarget) {
            return this.containerTarget;
        } else if (this.hasInputTarget) {
            return this.inputTarget;
        } else {
            return this.element;
        }
    }
}
