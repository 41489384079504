import {Controller} from '@hotwired/stimulus';
import {AxiosRequest} from '../helpers/axios_helper';
import {snakeToCamelCase} from '@js/utils';

export default class extends Controller {
    static targets = ["pagination"];

    static values = {
        lastHeader: {type: String, default: 'last_header'},
    }

    scroll() {
        let url = '#';

        if (this.hasPaginationTarget && this.element.offsetParent !== null)
        {
            url  = this.paginationTarget.querySelector("a[href][aria-label='Next']");
            if (url)
            {
                url = url.href;

                const el = document.querySelector('[data-controller="infinite-scroll"]');
                const rect = el.getBoundingClientRect();
                const wBottom  = window.scrollY + window.innerHeight;
                const elBottom = rect.top + rect.height + window.scrollY;

                if (wBottom + 1000 > elBottom){
                    this.paginationTarget.remove();
                    this.loadMore(url);
                }
            }
        }
    }

    loadMore(url){
        const _this = this;
        let lastHeader = this.element.querySelector(`#${this.lastHeaderValue}`);
        let lastNestedHeader = this.element.querySelector('#last_nested_header');
        let method;
        let params = new FormData();

        if (lastHeader) {
            params.append(this.lastHeaderValue, lastHeader.dataset[snakeToCamelCase(this.lastHeaderValue)]);
            lastHeader.remove();
        }

        if (lastNestedHeader) {
            params.append('last_nested_header', lastNestedHeader.dataset['lastNestedHeader']);
            lastNestedHeader.remove();
        }

        if (url.includes('search')) {
            method = 'post';
            document.querySelectorAll('.search-form').forEach(form => {
                _this.appendFormToFormData(params, form);
            });
        } else {
            method = 'get';
            params = Object.fromEntries(params.entries());
        }

        new AxiosRequest(url, method, params).sendTurboStream();
    }

    appendFormToFormData(formData, form) {
        const dataToAppend = new FormData(form);
        dataToAppend.forEach((value, key) => {
            formData.append(key, value);
        });
    }
}
