import {Controller} from '@hotwired/stimulus';
import {HTMLCustomSwal, InputTextSwal} from '@js/helpers/swal_helper';
import {AxiosRequest} from '@js/helpers/axios_helper';

export default class extends Controller {
    async convertToReport(event) {
        event.preventDefault();
        const translations = JSON.parse(event.target.dataset.translations);
        const {value: reportName} = await new InputTextSwal(translations).fire();

        if (reportName) {
            document.getElementById('report_name').value = reportName;
            event.target.form.requestSubmit();
        }
    }

    async addToCatalogue(event) {
        event.preventDefault();

        const element = event.target.closest('a')
        const translations = JSON.parse(element.dataset.translations);

        // Render catalogues select and get selected ids
        const {value: catalogueIds} = await new HTMLCustomSwal(translations).fire({
            html: document.getElementById('catalogues-select-template').innerHTML,
            showCancelButton: true,
            preConfirm: () => {
                const selectedOptions = document.getElementById('catalogue_ids').selectedOptions;

                // return ids of selected options
                return Array.from(selectedOptions).map(option => option.value);
            },
        });

        if (catalogueIds) {
            new AxiosRequest(
                `/reports/${element.dataset.reportId}/manage_catalogues`,
                'post',
                {catalogue_ids: catalogueIds})
                .sendTurboStream();
        }
    }
}
