import {Controller} from '@hotwired/stimulus';
import {AxiosRequest} from '@js/helpers/axios_helper';
import {formatItem} from '@js/helpers/select2/templates';
import {matcher} from '@js/helpers/select2/matchers';
import {Select2Helper} from '@js/helpers/select2_helper';

export default class extends Controller {
    static targets = ['conditionRow', 'selectMethod', 'selectConstraint', 'selectValues'];

    connect() {
        // prevent turbo cache
        Turbo.cache.exemptPageFromCache();

        (() => {
            // dispatch change event triggered by select2
            $(this.selectMethodTarget).on('select2:select', function () {
                const event = new Event('change', {bubbles: true});
                this.dispatchEvent(event);
            });

            // build select2 on connection
            $(this.selectMethodTarget).select2({language: window.lang(), placeholder: ''});
            $(this.selectConstraintTarget).select2({language: window.lang()});
            this.updateSelectValuesByMethod();
        })();
    }

    updateValues(_event) {
        this.updateSelectValuesByMethod();

        const data = {
            method: this.selectMethodTarget.value,
            target: this.selectValuesTarget.id,
            locale: window.lang(),
        };
        new AxiosRequest('/queries/update_values', 'post', data).sendTurboStream();
    }

    createScrollingSelect(obj) {
        const url = `/${window.lang()}/items/${this.selectMethodTarget.value.replace('by_', '')}`;
        const options = {formatType: this.itemFormatFromMethod()};
        const select = new Select2Helper(url, options);
        select.initializeSelect2(obj)
    }

    updateSelectValuesByMethod() {
        const method = this.selectMethodTarget.value;
        switch (method) {
            case 'by_appellation':
                this.createScrollingSelect($(this.selectValuesTarget));
                break;
            case 'by_region':
            case 'by_country':
                this.createScrollingSelect($(this.selectValuesTarget));
                break;
            case 'by_color_type':
                $(this.selectValuesTarget).select2({
                    placeholder: '',
                    language: window.lang(),
                    matcher: matcher('multi'),
                    templateResult: formatItem('color'),
                    templateSelection: formatItem('color'),
                });
                break;
            default:
                $(this.selectValuesTarget).select2({placeholder: '', language: window.lang()});
                break;
        }
    }

    itemFormatFromMethod() {
        const selectMethod = this.selectMethodTarget.value;
        switch (selectMethod) {
            case 'by_country':
            case 'by_appellation':
                return selectMethod.replace('by_', '');
            default:
                return '';
        }
    }
}
