import {Controller} from '@hotwired/stimulus';
import hotkeys from 'hotkeys-js';

export default class extends Controller {
    static values = {
        keys: { type: Object, default: {} }
    };

    connect() {
        Object.entries(this.keysValue).forEach(([key, value]) => {
            hotkeys(key, (event, _handler) => {
                event.preventDefault();
                Turbo.visit(value, { frame: '_top' })
            });
        });
    }

    disconnect() {
        Object.keys(this.keysValue).forEach(key => {
            hotkeys.unbind(key);
        });
    }
}
