import {Carousel} from 'bootstrap';
import {addMultipleEventListeners} from '@js/utils';

function initCarousel() {
    addMultipleEventListeners(window, ['turbo:load', 'turbo:render'], setCarousel);
}

function setCarousel() {
    const carousels = document.querySelectorAll('#carousel');
    carousels.forEach((carousel) => { new Carousel(carousel); });
}

export {initCarousel};
