import {Controller} from '@hotwired/stimulus';
import {Modal} from 'bootstrap';

export default class extends Controller {
    static targets = ['modal'];

    connect() {
        this.modal = new Modal(this.modalTarget.children[0]);
        this.form = this.element.querySelector('form');

        this.updateFilterCount(this.filterCount(this.form));
    }

    clear(event) {
        event.preventDefault();

        // We cannot use reset() because of the cache
        // reset() set values to default ones (cached ones if there are) and does not clear the form

        // reset checkboxes
        this.form
            .querySelectorAll('input[type="checkbox"]')
            .forEach((checkbox) => {
                checkbox.checked = false;
            });

        // reset selects
        this.form
            .querySelectorAll('select')
            .forEach((select) => {
                $(select).val(null).trigger('change');
            });

        // reset flatpickrs
        this.form
            .querySelectorAll('[data-flatpickr]')
            .forEach(input => {
                input._flatpickr.clear();
            });

        // reset hidden inputs
        this.form
            .querySelectorAll('input[type="hidden"]:not([id="hidden-keywords-field"])')
            .forEach((input) => {
                input.value = '';
            });
    }

    updateAndClose(event) {
        this.updateFilterCount(this.filterCount(event.target));
        this.modal.hide();
    }

    updateFilterCount(count) {
        const filterCount = document.getElementById('filter-count');
        filterCount.innerHTML = '';

        if (count !== 0) {
            let badge = document.createElement('div');
            badge.innerHTML = String(count);
            filterCount.appendChild(badge);
        }
    }

    filterCount(form) {
        const fields = form.elements;
        const formData = new FormData(form);

        // Remove inputs with ignore filter count attribute from the form data
        Array.from(fields).forEach(input => {
            if (input.dataset.ignoreFilterCount) {
                formData.delete(input.name);
            }
        })

        return Array.from(formData.entries())
            .filter(input => !['authenticity_token', 'keywords'].includes(input[0]) && input[1] !== '')
            .length;
    }
}
