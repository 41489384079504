import {kebabToCamelCase, kebabToSnakeCase} from '@js/utils';

function getPropFromItem(item, key) {
    const camelKey = kebabToCamelCase(key);
    const snakeKey = kebabToSnakeCase(key);
    return item.element?.dataset[camelKey] ? item.element.dataset[camelKey] : item[snakeKey];
}

function defaultTemplate(item) {
    if (item.element === undefined) {
        return $.fn.select2.defaults.defaults.templateSelection(item);
    } else {
        return $.fn.select2.defaults.defaults.templateResult(item);
    }
}

function flagTemplate(item) {
    const country = getPropFromItem(item, 'iso-code');

    // <span><i class='fi fi-xx me-1'></i> country name</span>
    const span = document.createElement('span');
    const icon = document.createElement('i');
    icon.className = `fi fi-${country} me-1`;
    span.appendChild(icon);
    span.appendChild(document.createTextNode(` ${item.text}`));
    return span;
}

function colorTemplate(item) {
    const color = getPropFromItem(item, 'color');

    // <span><i class='fas fa-circle me-1' style='color: #000000'></i> color name</span>
    const span = document.createElement('span');
    const icon = document.createElement('i');
    icon.className = 'fas fa-circle me-1';
    icon.style.color = color ?? 'white';
    span.appendChild(icon);
    span.appendChild(document.createTextNode(` ${item.text}`));
    return span;
}

function appellationTemplate(item) {
    const showEquivalents = item.element === undefined; // Only show equivalents for selection (when no element)
    const isoCode = getPropFromItem(item, 'iso-code');
    const equivalents = getPropFromItem(item, 'equivalents');

    // <i class="fi fi-xx me-1"></i>appellation name<div style="font-size: 12px; font-style: italic;">equivalents</div>
    const span = document.createElement('span');
    const icon = document.createElement('i');
    icon.className = `fi fi-${isoCode} me-1`;
    span.appendChild(icon);
    span.appendChild(document.createTextNode(item.text));
    if (showEquivalents && equivalents) {
        equivalents.filter(equivalent => Object.keys(equivalent).length > 0)
            .forEach(equivalent => {
                for (const [key, values] of Object.entries(equivalent)) {
                    const div = document.createElement('div');
                    div.style.fontSize = '12px';
                    div.style.fontStyle = 'italic';
                    div.appendChild(document.createTextNode(`${key} = ${values.join(', ')}`));
                    span.appendChild(div);
                }
            });
    }
    return span;
}

export function formatItem(kind) {
    switch (kind) {
        case 'appellation':
            return (item) => appellationTemplate(item);
        case 'color':
            return (item) => colorTemplate(item);
        case 'country':
        case 'language':
            return (item) => flagTemplate(item);
        default:
            return (item) => defaultTemplate(item);
    }
}
