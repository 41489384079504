import {Controller} from '@hotwired/stimulus';
import {AxiosRequest} from '@js/helpers/axios_helper';

export default class extends Controller {
    static targets = ['pagination'];

    static values = {
        scrollMethod: {type: String, default: 'post'},
    };

    loadItems(event) {
        if (this.hasPaginationTarget) {
            let url = this.paginationTarget.querySelector("a[href][aria-label='Next']");

            if (url) {
                url = new URL(url.href);

                let dom = event.currentTarget;
                let sTop = dom.scrollTop;
                let sHeight = dom.scrollHeight;
                let cHeight = dom.clientHeight;

                if ((sHeight - (sTop + cHeight) < 100)) {
                    this.paginationTarget.remove();
                    new AxiosRequest(url,
                        'get').sendTurboStream();
                }
            }
        }
    }
}
