import {Controller} from '@hotwired/stimulus';
import { Radar } from '../helpers/radar_helper';
import { Sunburst } from '../helpers/sunburst_helper';

export default class extends Controller {
    static values = {
        data: {type: Object, default: {}},
        plotType: {type: String, default: ''},
        title: {type: String, default: ''},
    };

    static targets = ['plot'];

    plotTargetConnected() {
        (() => {
            // Wait for the element to be visible to plot
            const observer = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    try {
                        this.check_kind();
                        this.plot();

                        // Remove download button
                        document.querySelectorAll('.modebar-btn').forEach((el) => {
                            el.classList.add('d-none');
                        });

                        // Get last clicked aroma
                        if (this.plotTypeValue === 'sunburst') {
                            this.watchSunburstForReset();
                        }

                        observer.disconnect();
                    } catch (e) {
                        console.log(e.message);
                        this.element.remove();
                    }
                }
            }, {
                root: null,
                threshold: 0
            });

            observer.observe(this.element);
        })();
    }

    plot() {
        this.plotlyPlot = this.plotObject();

        if (this.plotTypeValue === 'radar') {
            this.plotlyPlot.fillColor(this.dataValue.color);
            this.plotlyPlot.stylizeLabels();
            this.plotlyPlot.add_default();
        }

        this.plotlyPlot.config.toImageButtonOptions.filename = `${this.plotTypeValue}__${this.titleValue}`;

        this.plotlyPlot.plot_on(this.plotTarget);
    }

    relayout() {
        if (this.plotlyPlot === undefined) { return; } // Do not relayout if plotlyPlot is not defined
        Plotly.relayout(this.plotTarget,
            {autosize: true});
    }

    check_kind() {
        if(!['radar', 'sunburst'].includes(this.plotTypeValue)) {
            throw new Error('Le type de graphique n\'est pas dans la liste');
        }
    }

    plotObject() {
        switch (this.plotTypeValue) {
            case 'radar':
                return new Radar(this.dataValue);
            case 'sunburst':
                return new Sunburst(this.dataValue);
            default:
                console.log('Type de graphique non pris en compte');
        }
    }

    downloadPlot() {
        this.plotlyPlot.download(this.plotTarget);
    }

    watchSunburstForReset() {
        const _this = this;
        this.plotTarget.on('plotly_sunburstclick', data => {
            let currentData = data.points[0].data;
            let parent = data.points[0].parent;

            if (_this.last_parent === data.nextLevel) {
                currentData.level = '';
                _this.last_parent = null;
                Plotly.redraw(_this.plotTarget, currentData);

                return false;
            }

            _this.last_parent = data.nextLevel ? parent : _this.last_parent;
        });
    }
}
