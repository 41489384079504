import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        multiple: {type: Boolean, default: false},
    };

    connect() {
        this.checked = new Set();
        let _this = this;
        this.element
            .querySelectorAll('input:checked')
            .forEach((checkbox) => {
                _this.checked.add(checkbox.value);
            });
    }

    check(event) {
        // Get selectable dom and input checkbox
        let checkboxElement = event.currentTarget.querySelector(event.params.checkboxElement);
        let checkbox = checkboxElement.previousElementSibling; // CAREFUL: Checkbox has to be the previous sibling element

        // If not multiple, uncheck checked item
        if (!this.multipleValue) {
            let checkedItem = this.element.querySelector('input:checked');
            if (checkedItem && checkedItem !== checkbox) {
                checkedItem.checked = false;
            }
        }

        // Select dom
        // 1) Get checkbox and set to right value
        // CAREFUL: Checkbox has to be the previous sibling element
        if (checkbox.checked) {
            checkbox.checked = false;
            this.checked.delete(checkbox.value);
        } else {
            checkbox.checked = true;
            this.checked.add(checkbox.value);
        }
    }
}
