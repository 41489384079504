import {Controller} from '@hotwired/stimulus';
import {AxiosRequest} from '@js/helpers/axios_helper';

export default class extends Controller {
    static targets = ['selectableItem', 'counter'];

    static values = {
        checkboxName: {type: String, default: ''},
        withSearch: {type: Boolean, default: false},
        fetchIdsUrl: {type: String, default: ''},
        searchInputId: {type: String, default: '#keywords'},
        submitButtonId: {type: String, default: '#multiselect-submit'},
        selectedItems: {type: Array, default: []},
    };

    static classes = ['selected'];

    initialize() {
        const _this = this;
        this.selectedItems = new Set(this.selectedItemsValue.map(String));

        (() => {
            this.updateSubmitButton();
            this.updateCounter();
        })();

        this.element.addEventListener('turbo:submit-start', (event) => {
            Array.from(_this.selectedItems)
                .forEach((item) => {
                    event.detail.formSubmission.fetchRequest.body.append(_this.checkboxNameValue, item);
                });
        });
    }

    selectableItemTargetConnected(selectableItem) {
        const checkbox = selectableItem.querySelector('.checkbox input');

        if (this.selectedItems.has(checkbox.value)) {
            checkbox.checked = true;
            selectableItem.classList.add(this.selectedClass);
        }
    }

    toggleSelect(event) {
        this.toggleSelectItem(event.currentTarget);
    }

    toggleSelectItem(selectableItem, modes = ['select', 'unselect']) {
        const checkbox = selectableItem.querySelector('.checkbox input');
        // Toggle select
        if (checkbox.checked && modes.includes('unselect')) {
            checkbox.checked = false;
            this.selectedItems.delete(checkbox.value);
            selectableItem.classList.remove(this.selectedClass);
        } else if (modes.includes('select')) {
            checkbox.checked = true;
            this.selectedItems.add(checkbox.value);
            selectableItem.classList.add(this.selectedClass);
        }

        this.updateCounter();
        this.updateSubmitButton();
    }

    toggleSelectAll(event) {
        if (this.withSearchValue) {
            let nResults = document.querySelector('.n_results')?.textContent;
            nResults = parseInt(nResults) || -1;

            const selectableItemsCount = this.selectableItemTargets.length;

            const _this = this;
            const button = event.currentTarget;
            const mode = button.dataset.mode;

            // Two cases:
            // 1: Number of selectable items is equal to number of results.
            //    In this case, get selectable ids on page

            if (selectableItemsCount === nResults) {
                this.selectableItemTargets.forEach((item) => _this.toggleSelectItem(item, [mode]));
            } else {
                this.fetchIds([mode]);
            }

            // Change button text
            const inverseMode = mode === 'select' ? 'unselect' : 'select';
            const buttonText = button.getAttribute(`data-text-${inverseMode}`);
            button.dataset.mode = inverseMode;
            button.textContent = buttonText;
        }
    }

    updateCounter() {
        const selectedCount = this.selectedItems.size;
        const text = this.counterTarget.dataset[selectedCount < 2 ? 'textSingular' : 'textPlural'];

        this.counterTarget.textContent = `${selectedCount} ${text}`;
    }

    updateSubmitButton() {
        const submitButton = document.querySelector(this.submitButtonIdValue);

        submitButton.disabled = this.selectedItems.size === 0;
    }

    fetchIds(modes) {
        const _this = this;

        const url = new URL(this.fetchIdsUrlValue,
            document.location);

        if (this.withSearchValue) {
            // APPEND Query params if search is
            const searchInput = this.searchInputIdValue.value;
            if (searchInput) {
                url.searchParams.append('keywords', searchInput);
            }
        }

        new AxiosRequest(url, 'get').sendHtml()
            .then(response => {
                const data = response.data;

                // CAST ids as String to be sure that comparison are on same types
                const ids = new Set([...data.ids.map(String)]);

                _this.selectedItems = modes.includes('select') ? new Set([..._this.selectedItems, ...ids])
                    : new Set(Array.from(_this.selectedItems)
                        .filter(item => !ids.has(item)));
            })
            .finally(() => {
                _this.selectableItemTargets
                    .forEach((item) => _this.toggleSelectItem(item, modes));
            });
    }
}
