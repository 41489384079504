function defaultMatch(params, data) {
    return $.fn.select2.defaults.defaults.matcher(params, data);
}

function multiMatch(params, data, withoutParentheses = false) {
    // If there are no search terms, return all the data
    if (params.term == null || params.term.trim() === '') {
        return data;
    }

    // Do not display the item if there is no 'text' property
    if (typeof data.text === 'undefined') {
        return null;
    }

    // `params.term` should be the term that is used for searching
    // `data.text` is the text that is displayed for the data object
    let value = data.text.toLowerCase();
    let term = params.term.toLowerCase();

    // Split the search term into individual words and check if all of them are included in the value
    let match = term.split(/\s+/).every(termWord => value.includes(termWord));

    // Return the data object if there is a match
    if (match) {
        return data;
    }

    // Return `null` if the term should not be displayed
    return null;
}

export function matcher(kind) {
    switch (kind) {
        case 'multi':
            return (params, data) => multiMatch(params, data);
        default:
            return (params, data) => defaultMatch(params, data);
    }
}
