import Autocomplete from 'stimulus-autocomplete';

export default class extends Autocomplete {
    static values = {
        clearOnFocusOut: {type: Boolean, default: false},
        clearable: {type: Boolean, default: false},
        scrollable: {type: Boolean, default: false},
    };

    connect() {
        super.connect();

        if (this.clearOnFocusOutValue) {
            this.inputTarget.addEventListener('focusout', () => this.clear());
        }

        if (this.clearableValue) {
            this.setClearable();
        }

        if (this.scrollableValue) {
            this.resultsTarget.dataset.controller = 'autocompletes--autocomplete-scroll';
            this.resultsTarget.dataset.action = 'scroll->autocompletes--autocomplete-scroll#loadItems';
        }
    }

    open() {
        super.open();

        this.resultsTarget.scrollTop = 0; // reset scroll

        // scroll one time at opening
        if (this.scrollableValue) {
            this.resultsTarget.dispatchEvent(new CustomEvent('scroll', {bubbles: true}));
        }

        this.resultsPosition();
    }

    replaceResults(html) {
        // don't replace results if clear on focus option is set and the input is not focused anymore
        if (this.clearOnFocusOutValue && document.activeElement !== this.inputTarget) {
            return;
        }

        super.replaceResults(html);
    }

    // Inspired by select2
    resultsPosition() {
        const autocompleteContainer = this.element.closest('.autocomplete-container');
        if (!autocompleteContainer) return;

        const autocompleteContainerRect = autocompleteContainer.getBoundingClientRect();

        let newDirection = null;

        let offset = {
            top: autocompleteContainerRect.top,
            left: autocompleteContainerRect.left,
        };

        offset.bottom = offset.top + autocompleteContainerRect.height;

        let container = {
            height: autocompleteContainerRect.height,
        };

        container.top = offset.top;
        container.bottom = offset.top + container.height;

        let dropdown = {
            height: this.resultsTarget.getBoundingClientRect().height,
        };

        let viewport = {
            top: window.scrollY,
            bottom: window.scrollY + window.innerHeight,
        };

        let enoughRoomAbove = viewport.top < (offset.top - dropdown.height + 50); // add 50px because of navbar
        let enoughRoomBelow = viewport.bottom > (offset.bottom + dropdown.height + 50); // add 50px because of navbar

        if (!enoughRoomAbove && enoughRoomBelow) {
            newDirection = 'below';
        } else if (!enoughRoomBelow && enoughRoomAbove) {
            newDirection = 'above';
        }
        this.resultsTarget.classList.remove('above', 'below');
        this.resultsTarget.classList.add(newDirection);
    }

    setClearable() {
        // No need to call clear() method because when we click the button, the focusout event will be triggered
        this.element.classList.add('autocomplete-clearable');
        this.inputTarget.insertAdjacentHTML('afterend', '<div class="autocomplete-clear"</div>');

        // on click clear: clear input, close results and focus input
        const clearButton = this.element.querySelector('.autocomplete-clear');
        clearButton.addEventListener('click', () => {
            this.clear();
            this.close();
            this.inputTarget.focus();
        });
    }
}
