import AutocompleteStrict from '@controllers/autocompletes/autocomplete_strict_controller';

export default class extends AutocompleteStrict {
    static targets = ['appellationId'];

    commit(selected) {
        super.commit(selected);

        this.appellationIdTarget.value = selected.dataset.autocompleteAppellationId;
    }

    // Override onInputFocusOut
    originOnInputFocusOut = this.onInputFocusOut;
    onInputFocusOut = () => {
        this.originOnInputFocusOut();
        if (this.hiddenTarget.value === '') this.appellationIdTarget.value = '';
    };
}
