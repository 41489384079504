import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        waitTime: Number,
        inputId: { type: String, default: 'keywords' }
    }

    request(_event) {
        const input = document.getElementById(this.inputIdValue);
        const form  = input.closest('form');
        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
            form.requestSubmit();
        }, this.waitTimeValue);
    }
}
