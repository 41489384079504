import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 'toggleElement' ]
    static values  = {
        scrollTop: Boolean,
    }

    hide_or_show() {
        this.toggleElementTargets.forEach((element) => {
            element.classList.toggle('d-none')
            element.classList.toggle('d-block')
        })
        if (this.scrollTopValue) window.scrollTo(0,0);
    }
}
